var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "planCollection", attrs: { id: "Collection-Content" } },
    [
      _c("h3", { staticStyle: { "font-size": "20px" } }, [
        _vm._v(_vm._s(_vm.collectionResult.planCreateCompanyName)),
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.collectionResult.chargingContent
            ? _c("p", [
                _vm._v("收费名称："),
                _c("span", [
                  _vm._v(_vm._s(_vm.collectionResult.chargingContent)),
                ]),
              ])
            : _vm._e(),
          _c("van-divider", { staticStyle: { background: "#02A0C6" } }),
          _c("p", [
            _vm._v("收费金额："),
            _c("span", { staticStyle: { color: "#c9171e" } }, [
              _vm._v("¥" + _vm._s(_vm.collectionResult.feeAmount)),
            ]),
          ]),
          _c("van-divider", { staticStyle: { background: "#02A0C6" } }),
          _c("p", { staticClass: "costDescription" }, [
            _vm._v("收费说明： "),
            _c("span", [
              _vm._v(
                "服务提供方：" + _vm._s(_vm.collectionResult.serviceProvider)
              ),
            ]),
            _vm.collectionResult.contain
              ? _c("span", [
                  _vm._v("包含：" + _vm._s(_vm.collectionResult.contain)),
                ])
              : _vm._e(),
            _vm.collectionResult.returnMsg
              ? _c("span", [
                  _vm._v("退改：" + _vm._s(_vm.collectionResult.returnMsg)),
                ])
              : _vm._e(),
            _vm.collectionResult.excluding
              ? _c("span", [
                  _vm._v("不含：" + _vm._s(_vm.collectionResult.excluding)),
                ])
              : _vm._e(),
            _c("span", [
              _vm._v("联系人：" + _vm._s(_vm.collectionResult.contacts)),
            ]),
          ]),
          _c("van-divider", { staticStyle: { background: "#02A0C6" } }),
          _vm._m(0),
          _c("van-divider", { staticStyle: { background: "#02A0C6" } }),
          _c("p", [
            _vm._v("费用发票说明："),
            _c("span", [
              _vm._v(_vm._s(_vm.collectionResult.invoiceDescription)),
            ]),
          ]),
          _c("van-divider", { staticStyle: { background: "#02A0C6" } }),
          _c("p", [
            _vm._v("您的联系方式为："),
            _c("span", [_vm._v(_vm._s(_vm.collectionResult.payerPhone))]),
          ]),
          _c("van-divider", { staticStyle: { background: "#02A0C6" } }),
          _c(
            "p",
            { staticClass: "paymentMethod" },
            [
              _vm._v("缴费方式： "),
              _c(
                "van-radio-group",
                {
                  staticStyle: { "margin-top": "10px" },
                  on: { change: _vm.payModeChange },
                  model: {
                    value: _vm.payMode,
                    callback: function ($$v) {
                      _vm.payMode = $$v
                    },
                    expression: "payMode",
                  },
                },
                [
                  _c(
                    "van-radio",
                    {
                      staticStyle: { "margin-bottom": "5px" },
                      attrs: { name: "1" },
                    },
                    [_vm._v("微信支付")]
                  ),
                  _c("van-radio", { attrs: { name: "2" } }, [
                    _vm._v("线下转账"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("van-divider", { staticStyle: { background: "#02A0C6" } }),
          _c("p", { staticClass: "paymentMethod" }, [
            _vm._v("付款备注： "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.remark,
                  expression: "remark",
                },
              ],
              staticStyle: {
                padding: "5px",
                border: "1px gainsboro solid",
                "margin-top": "10px",
                "border-radius": "5px",
              },
              domProps: { value: _vm.remark },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.remark = $event.target.value
                },
              },
            }),
          ]),
          _c("van-divider", { staticStyle: { background: "#02A0C6" } }),
        ],
        1
      ),
      _vm.payMode == 1 && !_vm.isPay
        ? _c(
            "button",
            {
              staticStyle: {
                "background-color": "#00aa00",
                border: "none",
                padding: "10px",
                color: "white",
                "border-radius": "5px",
                width: "80%",
                position: "relative",
                top: "-8px",
                left: "50%",
                transform: "translateX(-50%)",
              },
              on: { click: _vm.payPoint },
            },
            [_vm._v(" 立即支付 ")]
          )
        : _vm._e(),
      _vm.isPay
        ? _c(
            "div",
            {
              staticStyle: {
                color: "red",
                "text-align": "center",
                "font-size": "0.3rem",
                "font-weight": "bold",
                "margin-bottom": "0.1rem",
              },
            },
            [
              _vm._v(
                " 你已成功支付" + _vm._s(_vm.collectionResult.feeAmount) + "元 "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          style: { height: "75%" },
          attrs: { teleport: "body", position: "bottom" },
          model: {
            value: _vm.showBottom,
            callback: function ($$v) {
              _vm.showBottom = $$v
            },
            expression: "showBottom",
          },
        },
        [
          _c("van-field", {
            attrs: { placeholder: "请点击此处输入付款名称", label: "付款名称" },
            model: {
              value: _vm.payName,
              callback: function ($$v) {
                _vm.payName = $$v
              },
              expression: "payName",
            },
          }),
          _c("van-field", {
            attrs: {
              placeholder: "请点击此处选择付款时间",
              id: "time",
              type: "date",
              label: "付款时间",
            },
            on: { focus: _vm.removePlaceholder },
            model: {
              value: _vm.payDate,
              callback: function ($$v) {
                _vm.payDate = $$v
              },
              expression: "payDate",
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "margin-top": "10px",
                "padding-left": "15px",
              },
            },
            [
              _c("span", { staticStyle: { color: "#646566" } }, [
                _vm._v("上传支付凭证"),
              ]),
              _c("van-uploader", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  "after-read": _vm.afterRead,
                  "max-count": 1,
                  "preview-full-image": false,
                },
                model: {
                  value: _vm.waterBillPic,
                  callback: function ($$v) {
                    _vm.waterBillPic = $$v
                  },
                  expression: "waterBillPic",
                },
              }),
            ],
            1
          ),
          _c(
            "p",
            {
              staticStyle: {
                padding: "15px",
                "font-size": "12px",
                color: "black",
              },
            },
            [
              _vm._v(
                " 收款方: 麦格仕国际旅行社（北京）有限公司 账号信息：开户行及账号：浙商银行北京中关村支行1000000110120100016376 行号：316100000033 "
              ),
            ]
          ),
          _c(
            "button",
            {
              staticStyle: {
                "background-color": "#00aa00",
                border: "none",
                padding: "10px",
                color: "white",
                "border-radius": "5px",
                width: "80%",
                position: "absolute",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
              },
              on: { click: _vm.payApplication },
            },
            [
              _vm._v("确认已支付并通知收款方"),
              _vm.loadingShow
                ? _c("van-loading", {
                    staticStyle: { "padding-left": "5px" },
                    attrs: { size: "24px" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("缴费时段："),
      _c("span", [_vm._v("请在收到此缴费通知后48小时内缴费")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }