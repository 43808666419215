<template>
    <div class="planCollection" id="Collection-Content">
        <h3 style="font-size: 20px">{{ collectionResult.planCreateCompanyName }}</h3>
        <div class="content">
            <!--            <p>项目：<span>{{ collectionResult.projectName }}</span></p>-->
            <!--            <van-divider style="background: #02A0C6;"/>-->
            <!--            <p>日期：<span>{{ collectionResult.startDate | dateFormatPoint }}-{{ collectionResult.endDate | dateFormatPoint }}</span></p>-->
            <!--            <van-divider style="background: #02A0C6"/>-->
            <!--            <p>项目参与人：<span>{{ collectionResult.payer }}</span></p>-->
            <!--            <van-divider style="background: #02A0C6"/>-->
            <p v-if="collectionResult.chargingContent">收费名称：<span>{{ collectionResult.chargingContent }}</span></p>
            <van-divider style="background: #02A0C6" />
            <p>收费金额：<span style="color: #c9171e">¥{{ collectionResult.feeAmount }}</span></p>
            <van-divider style="background: #02A0C6" />
            <p class="costDescription">收费说明：
                <span>服务提供方：{{ collectionResult.serviceProvider }}</span>
                <span v-if="collectionResult.contain">包含：{{ collectionResult.contain }}</span>
                <span v-if="collectionResult.returnMsg">退改：{{ collectionResult.returnMsg }}</span>
                <span v-if="collectionResult.excluding">不含：{{ collectionResult.excluding }}</span>
                <span>联系人：{{ collectionResult.contacts }}</span>
            </p>
            <van-divider style="background: #02A0C6" />
            <p>缴费时段：<span>请在收到此缴费通知后48小时内缴费</span></p>
            <van-divider style="background: #02A0C6" />
            <p>费用发票说明：<span>{{ collectionResult.invoiceDescription }}</span></p>
            <van-divider style="background: #02A0C6" />
            <p>您的联系方式为：<span>{{ collectionResult.payerPhone }}</span></p>
            <van-divider style="background: #02A0C6" />
            <p class="paymentMethod">缴费方式：
                <van-radio-group @change="payModeChange" style="margin-top: 10px;" v-model="payMode">
                    <van-radio style="margin-bottom: 5px" name="1">微信支付</van-radio>
                    <van-radio name="2">线下转账</van-radio>
                </van-radio-group>
            </p>
            <van-divider style="background: #02A0C6" />
            <p class="paymentMethod">付款备注：
                <textarea style="padding: 5px;border: 1px gainsboro solid;margin-top: 10px;border-radius: 5px;"
                    v-model="remark" />
            </p>
            <van-divider style="background: #02A0C6" />
        </div>
        <button v-if="payMode == 1 && !isPay" @click="payPoint"
            style="background-color: #00aa00;border: none;padding: 10px;color: white;border-radius: 5px;width: 80%;position: relative;top: -8px;left: 50%;transform: translateX(-50%)">
            立即支付
        </button>
        <div v-if="isPay" style="color: red;text-align: center;font-size: 0.3rem;font-weight: bold;margin-bottom: 0.1rem;">
            你已成功支付{{ collectionResult.feeAmount }}元
        </div>
        <van-popup teleport="body" v-model:show="showBottom" position="bottom" :style="{ height: '75%' }">
            <van-field v-model="payName" placeholder="请点击此处输入付款名称" label="付款名称" />
            <van-field placeholder="请点击此处选择付款时间" id="time" v-model="payDate" @focus="removePlaceholder" type="date"
                label="付款时间" />

            <div style="display: flex;flex-direction: column;margin-top: 10px;padding-left: 15px">
                <span style="color: #646566">上传支付凭证</span>
                <van-uploader style="margin-top: 10px" :after-read="afterRead" v-model="waterBillPic" :max-count="1"
                    :preview-full-image="false" />
            </div>
            <p style="padding: 15px;font-size: 12px;color: black">
                收款方: 麦格仕国际旅行社（北京）有限公司 账号信息：开户行及账号：浙商银行北京中关村支行1000000110120100016376 行号：316100000033
            </p>
            <button @click="payApplication"
                style="background-color: #00aa00;border: none;padding: 10px;color: white;border-radius: 5px;width: 80%;position: absolute;bottom: 10px;left: 50%;transform: translateX(-50%)">确认已支付并通知收款方<van-loading
                    style="padding-left: 5px" v-if="loadingShow" size="24px"></van-loading>
            </button>
        </van-popup>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import PayApi from "@/components/Api/pay";
import qr from "@/components/Qr.vue";
import { mapMutations, mapState } from "vuex";
import { Toast } from 'vant';
import Api from "@/components/Api";
import { WechatPay } from "@/utils/wechatPay";

export default {
    name: "PlanCollection",
    data() {
        // 这里存放数据
        return {
            loadingShow: false,
            // 付款备注
            remark: '',
            // 付款账号
            payName: '',
            // 付款时间
            payDate: '',
            //
            showBottom: false,
            collectionId: '',
            // ciphertext: '',
            collectionResult: '',
            code: '',
            payMode: '1',
            payUrl: '',
            isPay: false,
            waterBillPic: []
        }
    },
    // import 引入的组件需要注入到对象中才能使用
    components: { qr },
    mixins: [WechatPay],
    props: {},
    // 方法集合
    methods: {
        ...mapMutations('collection', ['SET_OPENID', 'SET_COLLECTIONID', 'SET_CIPHERTEXT', 'SET_PLANID', 'SET_MAINID', 'SET_LINK_DATA', 'SET_SHOW_GROUP']),

        removePlaceholder() {
            let timeItem = document.getElementById("time");
            timeItem.removeAttribute("placeholder")
        },

        // 水单上传
        afterRead(file) {
            console.log("水单===》", this.waterBillPic)
        },
        payModeChange(e) {
            if (e === '2') {
                this.showBottom = true
            }
            if (e === '1') {
                // return new PayApi("pay").getH5Pay(this.collectionId,this.collectionResult.feeAmount).then(res => {
                //     this.payUrl = res
                // })
            }
        },
        payPoint() {
            if (this.payMode === '1') {
                if (this.$route.query.openid) {
                    return new PayApi("pay").getH5Pay(this.collectionId, this.collectionResult.feeAmount, this.$route.query.openid, this.remark, this.payId).then(res => {
                        console.log("getH5Pay", res)
                        if (res.code === 0) {
                            let map = JSON.parse(res.data)
                            console.log("getH5PayMap", map)
                            this.wexinPay(map, res => {
                                this.isPay = true
                                alert("支付成功!")
                                wx.miniProgram.redirectTo({url: `/orderPages/orderList/orderList`})
                            })
                        }
                    })
                }
            }
        },
        payApplication() {
            let content = ''
            let fileName = ''
            if (this.waterBillPic && this.waterBillPic.length > 0) {
                content = this.waterBillPic[0].content
                fileName = this.waterBillPic[0].file.name
            }
            let obj = {
                "payDate": this.payDate,
                "collectionId": this.collectionId,
                "payName": this.payName,
                "payPrice": this.collectionResult.feeAmount,
                "waterVoucherBase64": content,
                "waterVoucherName": fileName,
                "payId": this.payId,
            }
            console.log('请求的参数',obj);
            if (!this.payDate) {
                Toast("请选择日期")
                return
            }
            if (!this.collectionId) {
                Toast("收款ID错误")
                return
            }
            if (!this.payName) {
                Toast("请填写付款账户")
                return
            }
            if (Number(this.payPrice) < 0) {
                Toast("付款金额错误" + this.collectionResult.feeAmount)
                return
            }
            this.loadingShow = true
            if (!this.payId) {
                Toast("用户关联错误")
                return
            }
            new PayApi("pay").saveDetail(obj).then(res => {
                if (res.status === 200) {
                    Toast('发起成功');
                    wx.miniProgram.redirectTo({url: `/orderPages/orderList/orderList`})
                } else {
                    Toast('发起失败');
                }
                this.loadingShow = false
            })
        },
        getInfo(id) {
            new PayApi("pay").getInfo(id).then(res => {
                if (res.status == 200) {
                    this.collectionResult = res.data
                    this.SET_PLANID(res.data.planId)
                    this.SET_MAINID(res.data.budgetMainId)
                    this.SET_LINK_DATA(res.data.collectionLinkData)
                    if (res.data.collectionType == 4) {
                        this.SET_SHOW_GROUP(false)
                    } else {
                        this.SET_SHOW_GROUP(true)
                    }
                    if (this.$route.query.openid) {
                        this.SET_OPENID(this.$route.query.openid)
                    } else {
                        new Api("user").authorizationCallbackAddressH5Transmit("-1", location.href).then(res => {
                            window.open(res.data.replaceAll("amp;",""), '_self')
                        })
                    }
                }
            })
        },
        getRouteValue() {
            if (this.$route.query.collectionId) {
                let collectionId = this.$route.query.collectionId
                this.collectionId = collectionId
                let ciphertext = this.$route.query.ciphertext ? this.$route.query.ciphertext : this.ciphertext
                this.SET_COLLECTIONID(collectionId)
                this.SET_CIPHERTEXT(ciphertext)
                setTimeout(() => {
                    this.getInfo(collectionId)
                }, 1000)
            }
            setTimeout(() => {
                this.payModeChange("1")
            }, 1000)
            // 获取微信支付码
            // new PayApi("pay").getWechatPayCode({collectionId: this.collectionId, ciphertext: this.ciphertext}).then(res => {
            //     if (res.status == 200) {
            //         this.code = res.data
            //     }
            // })
        }
    },
    // 计算属性 类似于 data 概念
    computed: {
        ...mapState('collection', ['openId', 'ciphertext', 'payId'])
    },
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {
    },
    // 生命周期 - 挂载之前
    beforeMount() {
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
        let CollectionContent = document.getElementById("Collection-Content")
        document.body.overflow = 'hidden'
        let height = document.body.clientHeight - 50
        CollectionContent.style.maxHeight = height + "px"
        this.getRouteValue()
    },
    // 生命周期 - 更新之前
    beforeUpdate() {
    },
    // 生命周期 - 更新之后
    updated() {
    },
    // 生命周期 - 销毁之前
    beforeDestroy() {
        document.body.overflow = 'scroll'
    },
    // 生命周期 - 销毁完成
    destroyed() {
    },
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {
    },
    //离开的时候触发
    deactivated() {
    },
}
</script>

<!--<script>-->
<!--    let timeItem = document.getElementById("time");-->

<!--    // 获得焦点时去掉placeholder-->
<!--    timeItem.onfocus = function() {-->
<!--        this.removeAttribute("placeholder")-->
<!--    }-->

<!--    // 值发生改变后,如果值为空的话添加placeholder-->
<!--    timeItem.onchange = function() {-->
<!--        if(!this.value){-->
<!--            this.setAttribute("placeholder", "请选择日期");-->
<!--        }-->
<!--    }-->
<!--</script>-->

<style>
input[type="date"]::before {
    content: attr(placeholder);
    color: lightgray;
}
</style>

<style scoped lang="scss">
.planCollection {
    background: white;
    overflow-y: scroll;
    width: 100%;
    height: 100vh;
    position: absolute;

    //left: 50%;
    //transform: translateX(-50%);
    //top: 1.5px;
    >h3 {
        background: white;
        height: 50px;
        color: #e2041b;
        padding-left: 5px;
        display: flex;
        align-items: center;
        position: sticky;
        z-index: 100;
        top: 0;
    }

    .content {
        padding-left: 3px;
        padding-right: 7px;
        padding-top: 5px;

        >p {
            display: flex;
            flex-direction: column;
            color: #595857;
            padding-left: 5px;
            //background: #9fa0a0;
            //opacity: 0.6;
            font-weight: 700;
            margin-bottom: 3px;

            >span {
                color: gray;
                font-weight: 400;
                margin-top: 5px;
                text-align: justify;
                text-align-last: left;
                /*兼容ie*/
                text-justify: distribute-all-lines;
            }
        }

        .costDescription {
            display: flex;
            flex-direction: column;

            >span {
                margin-left: 10px;
                //text-decoration: underline;
                //text-underline-offset: 5px;
            }
        }

        .paymentMethod {
            display: flex;
            flex-direction: column;
            text-align: justify;
            text-align-last: left;
            /*兼容ie*/
            text-justify: distribute-all-lines;
        }

        >span {
            margin-left: 10px;
            //text-decoration: underline;
            //text-underline-offset: 5px;
        }

        .offline {
            display: flex;
            flex-direction: column;

            >span {}
        }
    }
}
</style>
